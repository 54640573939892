$propertyMap: (
  "m": "margin",
  "p": "padding"
);

/* Auto-generate classes for margin and padding */

.ml-auto { margin-left: auto !important; }
.mr-auto { margin-right: auto !important; }
.mt-auto { margin-top: auto !important; }
.mb-auto { margin-bottom: auto !important; }

@each $prefix, $property in $propertyMap {
  @for $i from 0 through 40 {
    .#{$prefix}l-#{$i} { #{$property}-left: #{$i}px !important; }
    .#{$prefix}r-#{$i} { #{$property}-right: #{$i}px !important; }
    .#{$prefix}t-#{$i} { #{$property}-top: #{$i}px !important; }
    .#{$prefix}b-#{$i} { #{$property}-bottom: #{$i}px !important; }
    .#{$prefix}x-#{$i} { #{$property}-left: #{$i}px !important; #{$property}-right: #{$i}px !important; }
    .#{$prefix}y-#{$i} { #{$property}-top: #{$i}px !important; #{$property}-bottom: #{$i}px !important; }
    .#{$prefix}a-#{$i} { #{$property}-left: #{$i}px !important; #{$property}-right: #{$i}px !important; #{$property}-top: #{$i}px !important; #{$property}-bottom: #{$i}px !important; }
  }
}
