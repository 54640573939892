@use "sass:map";
@use "sass:list";
@use '@angular/material' as mat;
@use './default-theme.scss' as light;
@use './dark-theme.scss' as dark;

$themes-palettes: (
  'light': (
    'primary': mat.define-palette(light.$webnu-primary-palette, A400, A100, A700),
    'accent': mat.define-palette(light.$webnu-accent-palette, A400, A100, A700),
    'warn': mat.define-palette(light.$webnu-warn-palette, A400, A100, A700),
    'background': mat.define-palette(light.$webnu-background-palette, A400, A100, A700),
  ),
  'dark': (
    'primary': mat.define-palette(dark.$webnu-primary-palette, A400, A100, A700),
    'accent': mat.define-palette(dark.$webnu-accent-palette, A400, A100, A700),
    'warn': mat.define-palette(dark.$webnu-warn-palette, A400, A100, A700),
    'background': mat.define-palette(dark.$webnu-background-palette, A400, A100, A700),
  )
);

@mixin set-bg-and-border-classes($theme: 'light') {
  $palette-map: ('lighter': A100, 'darker': A700, 'default': A400);
  $variations: 'lighter', 'default', 'darker';
  $palettes: 'primary', 'accent', 'warn', 'background';

  @each $palette in $palettes {

    @each $variation in $variations {

      @if ($variation == 'default') {
        .bg-#{$palette} {
          background-color: mat.get-color-from-palette(map.get(map.get($themes-palettes, $theme), $palette), map.get($palette-map, $variation)) !important;
        }

        .border-#{$palette} {
          border-width: 1px;
          border-style: solid;
          border-color: mat.get-color-from-palette(map.get(map.get($themes-palettes, $theme), $palette), map.get($palette-map, $variation)) !important;
        }

        .text-#{$palette} {
          color: mat.get-color-from-palette(map.get(map.get($themes-palettes, $theme), $palette), map.get($palette-map, $variation)) !important;
        }

      } @else {

        .bg-#{$palette}-#{$variation} {
          background-color: mat.get-color-from-palette(map.get(map.get($themes-palettes, $theme), $palette), map.get($palette-map, $variation)) !important;
        }

        .border-#{$palette}-#{$variation} {
          border-width: 1px;
          border-style: solid;
          border-color: mat.get-color-from-palette(map.get(map.get($themes-palettes, $theme), $palette), map.get($palette-map, $variation)) !important;
        }

        .text-#{$palette}-#{$variation} {
          color: mat.get-color-from-palette(map.get(map.get($themes-palettes, $theme), $palette), map.get($palette-map, $variation)) !important;
        }

      }
    }
  }
}

@mixin set-scrollbar-color($theme: 'light') {
  ::-webkit-scrollbar-track {
    background: mat.get-color-from-palette(map.get(map.get($themes-palettes, $theme), 'primary'), A400);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: mat.get-color-from-palette(map.get(map.get($themes-palettes, $theme), 'primary'), A400);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: mat.get-color-from-palette(map.get(map.get($themes-palettes, $theme), 'primary'), A400);
  }
}
