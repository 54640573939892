@use '@angular/material' as mat;
@use './default-theme.scss' as light;
@use './dark-theme.scss' as dark;
@import './classes.scss';

@include mat.core();

// TODO: move scrollbar colors to mixin

.light-theme {
  @include mat.all-component-themes(light.$webnu-light-theme);
  @include set-bg-and-border-classes('light');
  @include set-scrollbar-color('light');
	.white {color: white !important}
	.black {color: black !important}
}

.dark-theme {
  @include mat.all-component-colors(dark.$webnu-dark-theme);
  @include set-bg-and-border-classes('dark');
  @include set-scrollbar-color('dark');
}
