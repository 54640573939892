@use "styles/spaces_zone.scss";
@use '@angular/material' as mat;
@import './styles/main.scss';
@import '@angular/cdk/overlay-prebuilt.css';

html,
body {
	height: 100%;
	font-family: 'Poppins', sans-serif !important;
	margin: 0;
}

/* menu fonts */
@font-face {
	font-family: Anton;
	font-style: normal;
	font-weight: normal;
	src: url(./assets/fonts/Anton-Regular.ttf) format('truetype');
}

@font-face {
	font-family: Caveat;
	font-style: normal;
	font-weight: normal;
	src: url(./assets/fonts/Caveat-Regular.ttf) format('truetype');
}

@font-face {
	font-family: Oswald;
	font-style: normal;
	font-weight: normal;
	src: url(./assets/fonts/Oswald-Regular.ttf) format('truetype');
}

@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	src: url(./assets/fonts/RobotoSlab-Regular.ttf) format('truetype');
}

mat-form-field {
	width: 100%;
}

a {
	text-decoration: none;
}

h1 {
	font-family: 'Poppins' !important;
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 58px !important;
	line-height: 120% !important;

	@media screen and (max-width: 600px) {
		font-size: 42px !important;
	}
}

h2 {
	font-family: 'Poppins' !important;
	font-style: normal !important;
	font-weight: 700 !important;
	font-size: 48px !important;
	line-height: 120% !important;

	@media screen and (max-width: 600px) {
		font-size: 38px !important;
	}
}

h3 {
	font-family: 'Poppins' !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 32px !important;
	line-height: 120% !important;

	@media screen and (max-width: 600px) {
		font-size: 27px !important;
	}
}

h4 {
	font-family: 'Poppins' !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 24px !important;
	line-height: 120% !important;

	@media screen and (max-width: 600px) {
		font-size: 20px !important;
	}
}

.anton {
	font-family: 'Anton' !important;
}

.caveat {
	font-family: 'Caveat' !important;
	font-size: 20px;
}

.oswald {
	font-family: 'Oswald' !important;
}

.roboto {
	font-family: 'Roboto' !important;
}

.container {
	padding-right: 0;
	padding-left: 0;
	margin-right: auto;
	margin-left: auto;
}

@media (min-width: 768px) {
	.container {
		width: 750px;
	}
}

@media (min-width: 992px) {
	.container {
		width: 970px;
	}
}

@media (min-width: 1200px) {
	.container {
		width: 1200px;
	}
}

@media (min-width: 1360px) {
	.container {
		width: 100%;
		max-width: 1440px;
	}
}

.overflow-hidden {
	overflow: hidden;
}

.h-100 {
	height: 100%;
}

.h-100v {
	height: 100vh;
}

.w-100 {
	width: 100%;
}

.ma-auto {
	margin: auto !important;
}

.m-0 {
	margin: 0 !important;
}

.p-0 {
	padding: 0 !important;
}

.absolute {
	position: absolute !important;
}

.relative {
	position: relative !important;
}

.pointer {
	cursor: pointer !important;
}

.transparent {
	background-color: transparent !important;
}

.small-generic-btn {
	border-radius: 12px !important;
	height: 35px !important;
	padding: 0px 10px !important;
	border-width: 2px !important;
}

.generic-btn {
	border-radius: 12px !important;
	height: 48px !important;
	padding: 0px 40px !important;
	border-width: 2px !important;
}

mat-divider {
	display: block;
	margin: 10px 0 10px 0;
	border-top: 1px solid rgba(0, 0, 0, .12);
	width: 100%
}

.mat-mdc-menu-panel {
	padding: 12px !important;
	border-radius: 16px !important;
}

// --------------------------------------------------

.cdk-drag-placeholder {
	opacity: 0;
}

.cdk-drag-animating {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging mat-list-item:not(.cdk-drag-placeholder) {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
	box-sizing: border-box;
	border-radius: 4px;
	box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

// image-cropper div {
// 	.overlay {
// 		outline: none !important;
// 	}

// 	.cropper {
// 		outline: var(--cropper-outline-color, #17171796) solid 100vw !important; // This color is only for opacity in the image cooper!
// 	}
// }

// .image-container {
// 	width: 100%;
// 	max-width: 400px;
// }

.row-deleted {
	background-color: get-color("warn", "lighter") !important;

	td {
		color: get-color("warn", "lighter-contrast");
	}

	td:before {
		border-bottom: 1px solid get-color("warn", "lighter-contrast");
	}
}

.invisible {
	visibility: hidden !important;
}

.display-none {
	display: none !important;
}

.mat-dialog-container-no-padding {
	mat-dialog-container {
		padding: 0;
	}
}

.mat-dialog-container-bottom-padding {
	mat-dialog-container {
		padding: 0;
		padding-bottom: 24px;
	}
}

app-root {
	.mat-spinner {
		circle {
			stroke: get-color("primary", "default", "default") !important;
		}
	}
}

::-webkit-scrollbar {
	height: 4px;
	width: 4px;
}

::-webkit-scrollbar-thumb {
	border-radius: 14px;
}

.object-fit-img {
	object-fit: cover;
}

.theme-default .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
	display: flex;
}

// color when input detect error
.theme-default .mat-mdc-form-field-appearance-outline.mat-mdc-form-field-invalid.mat-mdc-form-field-invalid .mat-mdc-form-field-outline-thick {
	color: #d32f2f;
	background-color: #fbebeb !important;
	border-radius: 16px;
}

// background input color
.theme-default input.mat-input-element {
	background-color: transparent !important;
}

// hint color
.theme-default .mat-error {
	color: #d32f2f;
}

// focus input
.theme-default .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-thick {
	color: get-color("primary", "darker", "default");
}

// autofill color
input:-webkit-autofill {
	-webkit-text-fill-color: get-color("accent",
			"default",
			"default"
		) !important;
}

// borders
.mat-mdc-form-field-outline-start {
	border-radius: 16px 0 0 16px !important;
	min-width: 16px !important;
}

.mat-mdc-form-field-subscript-wrapper {
	font-size: 10px !important;
}

// start border
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
	border-top-left-radius: 12px !important;
	border-bottom-left-radius: 12px !important;
}

// end border
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
	border-top-right-radius: 12px !important;
	border-bottom-right-radius: 12px !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
	padding-top: 16px !important;
	padding-bottom: 10px !important;
}

.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mdc-notched-outline__notch {
	padding-top: 4px !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
	border-radius: 12px !important;
}

.mat-mdc-form-field-infix {
	min-height: 48px !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
	border-color: #78d603 !important;
	border-radius: 4px !important;
	background-color: #78d603 !important;
}

.mdc-button {
    letter-spacing: 0.5px !important;
}
